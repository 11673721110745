<template>
	
	<v-container>
			
		<v-breadcrumbs :items="breadCombs"></v-breadcrumbs>

		<v-row justify="end">
			<v-dialog v-model="dialog" persistent max-width="290">
				<template v-slot:activator="{ on }">
					<v-btn small dark v-on="on" class="primary mr-4">{{$vuetify.lang.t('$vuetify.label.add')}}</v-btn>
				</template>			
				<v-card>
					<v-card-title class="headline">{{$vuetify.lang.t('$vuetify.label.add')}}</v-card-title>
					<v-card-actions>
						<v-text-field
							v-model="inventoryId"
							:label="$vuetify.lang.t('$vuetify.label.inventoryId')"
							:error-messages="errorMessage"
						></v-text-field>
					</v-card-actions>
					<v-btn color="green darken-1" text @click="dialog = false">{{$vuetify.lang.t('$vuetify.base.cancel')}}</v-btn>
					<v-btn color="green darken-1" text @click="addLabel" class="mr-4">{{$vuetify.lang.t('$vuetify.base.add')}}</v-btn>
				</v-card> 
			</v-dialog>
		</v-row>
		<v-divider/>

		<v-spacer></v-spacer>
		<v-text-field
			v-model="search"
			:label="$vuetify.lang.t('$vuetify.label.searchText')"
			:error-messages="searchErrorMessage"
			@keyup.enter="searchChanged"
			@click:append="searchChanged"
		>

		</v-text-field>

		<v-data-table
			:headers="headers"
			:items="lables"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			disable-sort = true
			class="elevation-1"
			@update:options="optionsChanged"
		>
			<template v-slot:item.option="{item}">
				<v-btn text small color="primary" @click="routerTo(item)">{{$vuetify.lang.t('$vuetify.base.detail')}}</v-btn>
			</template>
		</v-data-table>

	</v-container>
</template>

<script>

export default {
		data: () => ({
			options: {
				page: 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageStop: 1
			},
			total: 0,
			pageCount: 1,
			errorMessage:"",
			inventoryId:"",
			search:null,
			searchErrorMessage:"",
			dialog:false,
			dense: false,
			fixedHeader:true,
			height:500,
			lables:[]
	}),
	computed:{
		headers(){
			return [
				{
					text: this.$vuetify.lang.t('$vuetify.label.id'), value: "id"
				},
				{
					text: this.$vuetify.lang.t('$vuetify.label.inventoryId'), value: "inventoryId"
				},
				{
					text: this.$vuetify.lang.t('$vuetify.label.productName'), value: "productName"
				},
				{
					text: this.$vuetify.lang.t('$vuetify.label.stockDate'), value: "stockDate"
				},
				{
					text: this.$vuetify.lang.t('$vuetify.label.op'), value: "option", sortable: false
				}
			];
		},
		breadCombs(){
			return [
					{
						text: this.$vuetify.lang.t('$vuetify.base.home'),
						disabled: false,
						href: '/home',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.base.labels'),
						disabled: true,
						href: 'labels',
					},
			]
		},
	},
	created (){
		this.fetchData()
	},
	methods:{
		fetchData(){
			this.$axios.get(
				"label",{
					params:{
						inventoryId:this.search,
						pageNo:this.options.page,
						pageSize:this.options.itemsPerPage
					}	
				}
				
			).then((response) => {
				this.lables = response.data.data;
				this.total = response.data.total;
				this.options.pageStop = response.data.totalPage
			}).catch((error) => {
				console.error(error)
			});
		},
		routerTo(item){
			// localStorage.setItem("labelDetail", JSON.stringify(item));
			this.$router.push({
				name: `LabelDetail`,
				params:{id:item.inventoryId}
			})
		},
		addLabel(){
			if(this.inventoryId == null || this.inventoryId == ""){
				this.errorMessage = "inventoryId required";
				return;
			}
			if(isNaN(this.inventoryId)){
				this.errorMessage = "not a number";
				return;
			}
			this.$axios.put(
				"label?inventoryId=" + this.inventoryId
			).then((response) => {
				
				if(response.data.code != 200){
					this.errorMessage = response.data.message;
					return;
				}
				this.dialog = false;
				this.inventoryId = "";
				const message = 'addLabel success';
				this.$store.dispatch('messages/addMessage', {message,messageClass: 'info'});
				this.fetchData();
			}).catch((error) => {
				console.error(error)
			});
		},
		optionsChanged() {
			this.fetchData();
		},
		searchChanged() {
			if(this.search == null || this.search == ""){
				this.search = null;
			}
			if(isNaN(this.inventoryId)){
				this.searchErrorMessage = "not a number";
				return;
			}
			this.fetchData();
		
		}
	}
}
</script>